$skills: "magento" 90,
"php" 90,
"wordpress" 70,
"mysql" 70,
"c-sharp" 70,
"git" 60,
"docker" 50,
"rabbitmq" 40,
"mongodb" 40;

@each $name, $percent in $skills {
  .#{$name} {
    width: $percent * 1%;
    -moz-animation: #{$name} 2s ease;
    -webkit-animation: #{$name} 2s ease;
  }

  @-moz-keyframes #{$name} {
    0% {
      width: 0px;
    }
    100% {
      width: $percent  * 1%;
    }
  }

  @-webkit-keyframes #{$name} {
    0% {
      width: 0px;
    }
    100% {
      width: $percent  * 1%;
    }
  }
  @keyframes #{$name} {
    0% {
      width: 0px;
    }
    100% {
      width: $percent  * 1%;
    }
  }
}


